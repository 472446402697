import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withKeys as _withKeys, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70083583"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-image-file-mobile" }
const _hoisted_2 = { class: "input-image-file-mobile-drop-area-container" }
const _hoisted_3 = { class: "input-image-file-mobile-drop-area" }
const _hoisted_4 = { class: "input-image-file-mobile-preview" }
const _hoisted_5 = {
  class: "input-image-file-mobile-name",
  "aria-live": "polite"
}
const _hoisted_6 = { class: "input-image-file-mobile-input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppImgFileSource = _resolveComponent("AppImgFileSource")!
  const _component_ButtonIcon = _resolveComponent("ButtonIcon")!
  const _component_InputFile = _resolveComponent("InputFile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "description", {}, undefined, true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.state.fileList)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.fileList, (file) => {
                  return (_openBlock(), _createElementBlock("figure", {
                    key: file.name
                  }, [
                    _createVNode(_component_AppImgFileSource, {
                      file: file,
                      alt: "preview"
                    }, null, 8, ["file"])
                  ]))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.fileName), 1),
              _createVNode(_component_ButtonIcon, {
                onClick: _withModifiers(_ctx.remove, ["prevent"]),
                onKeydown: _withKeys(_withModifiers(_ctx.remove, ["prevent"]), ["space","enter"]),
                icon: "minus-circle",
                styled: "is-none"
              }, null, 8, ["onClick", "onKeydown"])
            ], 64))
          : _renderSlot(_ctx.$slots, "placeholder", { key: 1 }, undefined, true)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_InputFile, {
        onInputFile: _ctx.change,
        "input-id": "input-image-file-mobile",
        label: _ctx.$t('parts.selectFile'),
        accept: "image/jpeg,image/png",
        ref: "inputImageFile"
      }, null, 8, ["onInputFile", "label"])
    ])
  ]))
}