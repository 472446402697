
import { defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'
import TheSearchImage from '@/components/organisms/TheSearchImage.vue'

export default defineComponent({
  components: {
    TheSearchImage,
  },
  props: {
    keyword: {
      type: String,
      default: '',
    },
  },
  setup () {
    const route = useRoute()
    const inputKeyword = computed(() => route.query.keyword)

    return {
      inputKeyword,
    }
  },
})
