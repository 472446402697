import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d14907a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheSearchImage = _resolveComponent("TheSearchImage")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TheSearchImage, { keyword: _ctx.inputKeyword }, null, 8, ["keyword"])
    ]),
    _createVNode(_component_router_view)
  ], 64))
}