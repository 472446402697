
import { defineComponent, reactive, computed, ref } from 'vue'
import ButtonIcon from './ButtonIcon.vue'
import InputFile from './InputFile.vue'
import AppImgFileSource from '../atoms/AppImgFileSource.vue'

export default defineComponent({
  components: {
    ButtonIcon,
    InputFile,
    AppImgFileSource,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, context) {
    const state = reactive<{
      dragOver: boolean,
      fileList: FileList | null
    }>({
      dragOver: false,
      fileList: null,
    })

    const fileName = computed(() => {
      if (state.fileList?.length) {
        const file = state.fileList[0]
        return file.name
      } else {
        return ''
      }
    })

    const change = (files: FileList) => {
      state.fileList = files
      context.emit('input-image', state.fileList)
    }

    const inputImageFile = ref()

    const remove = () => {
      state.fileList = null
      inputImageFile.value.clear()
      context.emit('input-image', state.fileList)
    }

    return {
      state,
      fileName,
      inputImageFile,
      change,
      remove,
    }
  },
})
