
import { defineComponent, reactive, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import DssIcon from '@/components/atoms/DssIcon.vue'
import AppInput from '../atoms/AppInput.vue'
import AppButton from '../atoms/AppButton.vue'
import InputImageFile from '@/components/molecules/InputImageFile.vue'
import InputImageFileMobile from '@/components/molecules/InputImageFileMobile.vue'
// import ModalSheet from '@/components/organisms/ModalSheet.vue'
import ModalSheetMobile from '@/components/organisms/ModalSheetMobile.vue'
import { getBlob } from '@/helpers/util/webApiUtil'

export default defineComponent({
  components: {
    DssIcon,
    AppInput,
    AppButton,
    InputImageFile,
    InputImageFileMobile,
    ModalSheetMobile,
  },
  props: {
    keyword: {
      type: String,
      default: '',
    },
  },
  setup (props) {
    const value = computed(() => props.keyword)
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const isVaildWebImageSearch = computed(() => state.imageUrl.length !== 0 && ['.jpg', '.png', '.bmp'].includes(state.imageUrl.substring(state.imageUrl.length - 4)))

    const state = reactive<{
      file: File | null
      imageUrl: string
      showModal: boolean
    }>({
      file: null,
      imageUrl: '',
      showModal: false,
    })

    const searchImage = async (files: FileList | null) => {
      if (files) {
        state.file = files[0]
      } else {
        return
      }
      try {
        await store.dispatch('uploadFile', state.file)
        router.push({
          name: 'SearchImageResult',
          query: {
            image: store.getters?.PictureFile?.key || state.file?.name,
          },
        })
        // 検索実行したらモーダルを閉じる
        state.showModal = false
      } catch (error: any) {
        // TODO エラーハンドリング
        console.error(error.message)
      }
    }

    const search = async (keyword: string) => {
      if (route.query?.image) {
        router.push({
          name: 'SearchImageResult',
          query: {
            image: route.query?.image,
            keyword,
          },
        })
      }
    }

    let isSearching = false

    const searchImageByUrl = async (url: string) => {
      if (isSearching) return
      isSearching = true
      if (isVaildWebImageSearch.value) {
        const blob = await getBlob(`${process.env.VUE_APP_PICTURE_API_URL}?url=${encodeURIComponent(url)}`)
        await store.dispatch('uploadFile', blob)
        router.push({
          name: 'SearchImageResult',
          query: {
            image: store.getters?.PictureFile?.key,
          },
        })
      }
      isSearching = false
    }

    const searchKeyword = () => {
      store.commit('SET_OPEN_CLOSURE', false)
    }

    const isServer = typeof window === 'undefined'
    onMounted(() => {
      if (isServer) return
      const headerSiteName = document.getElementById('the-global-header-site-name') as HTMLElement
      headerSiteName.focus()

      store.commit('CACHE_QUERY', {
        ...route.query,
      })
    })

    return {
      state,
      isVaildWebImageSearch,
      value,
      searchImage,
      search,
      searchImageByUrl,
      searchKeyword,
    }
  },
})
