import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-532e42d7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "the-search-image" }
const _hoisted_2 = { class: "the-search-image-header has-background-contents shadow" }
const _hoisted_3 = { class: "has-text-table-cell-title has-text-title" }
const _hoisted_4 = { class: "the-search-image-show-modal is-hidden-desktop has-background-contents shadow level-center is-mobile" }
const _hoisted_5 = { class: "the-search-image-body level" }
const _hoisted_6 = { class: "the-search-image-url has-background-contents shadow" }
const _hoisted_7 = { role: "search" }
const _hoisted_8 = { class: "has-text-modest" }
const _hoisted_9 = { class: "level-right is-mobile" }
const _hoisted_10 = { class: "the-search-image-footer level-right is-mobile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_DssIcon = _resolveComponent("DssIcon")!
  const _component_InputImageFile = _resolveComponent("InputImageFile")!
  const _component_InputImageFileMobile = _resolveComponent("InputImageFileMobile")!
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_ModalSheetMobile = _resolveComponent("ModalSheetMobile")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('label.imageSearch')), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('label.imageSearchDescription')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_AppButton, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.state.showModal = true)),
        onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.state.showModal = true), ["prevent"]), ["space","enter"])),
        styled: "is-none",
        class: "has-text-link"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('label.imageSearchSelectFile')), 1)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_ModalSheetMobile, {
      show: _ctx.state.showModal,
      onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.state.showModal = false)),
      title: _ctx.$t('label.imageSearchSelectFile'),
      id: "the-search-image-modal-sheet"
    }, {
      modal: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_InputImageFile, {
            onInputImage: _cache[2] || (_cache[2] = ($event: any) => (_ctx.searchImage($event))),
            class: "flex-grow is-hidden-mobile",
            role: "search"
          }, {
            description: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('label.imageSearchByFile')), 1)
            ]),
            placeholder: _withCtx(() => [
              _createVNode(_component_DssIcon, { icon: "image" }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('label.imageSearchPlaceholder')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_InputImageFileMobile, {
            onInputImage: _cache[3] || (_cache[3] = ($event: any) => (_ctx.searchImage($event))),
            class: "is-hidden-desktop flex-grow has-background-contents shadow",
            role: "search"
          }, {
            description: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('label.imageSearchByFile')), 1)
            ]),
            placeholder: _withCtx(() => [
              _createVNode(_component_DssIcon, { icon: "image" }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('label.imageSearchByCameraImage')), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('label.imageSearchByWebImage')), 1),
            _createElementVNode("form", _hoisted_7, [
              _createVNode(_component_AppInput, {
                modelValue: _ctx.state.imageUrl,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.imageUrl) = $event)),
                placeholder: "https://website/image.jpg",
                label: _ctx.$t('label.imageUrl')
              }, null, 8, ["modelValue", "label"]),
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('label.webImageSearchDescription')), 1),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_AppButton, {
                  onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.searchImageByUrl(_ctx.state.imageUrl)), ["prevent"])),
                  onKeydown: _cache[6] || (_cache[6] = _withKeys(_withModifiers(($event: any) => (_ctx.searchImageByUrl(_ctx.state.imageUrl)), ["prevent"]), ["space","enter"])),
                  styled: "is-accent",
                  type: "submit",
                  disabled: !_ctx.isVaildWebImageSearch
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('parts.search')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["show", "title"]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_router_link, { to: { name: 'DetailedSearch' } }, {
        default: _withCtx(() => [
          _createElementVNode("button", {
            onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.searchKeyword && _ctx.searchKeyword(...args))),
            onKeydown: _cache[9] || (_cache[9] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.searchKeyword && _ctx.searchKeyword(...args)), ["prevent"]), ["space","enter"])),
            id: "search-button",
            class: "has-text-link",
            type: "button",
            "data-cy": "search-button",
            tabindex: "-1"
          }, _toDisplayString(_ctx.$t('parts.keywordsSearch')), 33)
        ]),
        _: 1
      })
    ])
  ]))
}